import React, { useState } from 'react'
import { Box, Button, Heading, Text, Input, VStack } from '@chakra-ui/react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import StripePayment from '../components/StripePayment';
import { useDispatch, useSelector } from 'react-redux';
import { startTrialAsync } from '../redux/actionCreators/thunks/userThunk';
import { useNavigate } from "react-router-dom";
const Subscribe = () => {
  let navigate = useNavigate();
  const userInfo = useSelector((state) => state.auth.user || {});
  const [email, setEmail] = useState(userInfo.email || "");
  const dispatch = useDispatch();
  const handleButtonClick = () =>{
  dispatch(startTrialAsync(email));
  navigate("/");
  }
  return (
    <>
    <Header />
     <Box
      p={4}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="full"
      height="60vh"
      backgroundColor="gray.50"
    >
      <VStack
        spacing={4}
        p={5}
        backgroundColor="white"
        boxShadow="md"
        borderRadius="lg"
        w="sm"
        align={"center"}
      >
        <Heading size="lg">Purchase Membership</Heading>
        <VStack spacing={3} align="center" w="full" py={4}>
          <Text fontSize="lg" fontWeight="bold">
            Monthly $1.00
          </Text>
        </VStack>
        {userInfo["trialUsed"] === true && <StripePayment/> }

        {userInfo["trialUsed"] === false &&  <Button type="submit"colorScheme="blue" w="full" bgColor="#000000" onClick={() => handleButtonClick()}>Start Trial</Button>}
      </VStack>
      
      
      
    </Box>
    <Footer/>
    </>
  )
}

export default Subscribe;
